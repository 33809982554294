import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import Header from '../../components/Header';

const LoginPage = ({setAuthenticated}) => {
  const [loginError, setLoginError] = useState(false);

  const handleFormSubmit = (values) => {
    setAuthenticated(true);
    if (values.username === 'example' && values.password === 'password') {
      // Successful login
      console.log('Login successful');
      // You may navigate to another page or update the UI accordingly
    } else {
      // Failed login
      setLoginError(true);
    }
  };

  const initialValues = {
    username: '',
    password: '',
  };

  const loginSchema = yup.object().shape({
    username: yup.string().required('Required'),
    password: yup.string().required('Required'),
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Container maxWidth="sm">
        <Box
          p="20px"
          border="1px solid #ccc"
          borderRadius="8px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          textAlign="center"
        >
          <img src="/assets/badge.svg" alt="Logo" style={{ width: '100px' }} /> {/* Using the SVG from public/assets */}

          <Header subtitle="Please login to continue." />

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={loginSchema}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="Username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="password"
                    label="Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                  />
                  {loginError && (
                    <Typography variant="body2" color="error">
                      Incorrect username or password.
                    </Typography>
                  )}
                </Box>
                <Box display="flex" justifyContent="center" mt="20px">
                  <Button type="submit" color="primary" variant="contained">
                    Login
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginPage;
