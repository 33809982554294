import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";

import Topbar from "./pages/global/Topbar";
import { useState } from "react";
import Dashboard from "./pages/dashboard";
import Team from "./pages/team";
import Invoices from "./pages/invoices";
import Contacts from "./pages/contacts";
import StudentForm from "./pages/form/student";
import ExaminationForm from "./pages/form/examination";
import Calendar from "./pages/calendar";
import Bar from "./pages/bar";
import Line from "./pages/line";
import Pie from "./pages/pie";
import FAQ from "./pages/faq";
import Geography from "./pages/geography";
import LoginPage from "./pages/auth/login";

const App = () => {
  const [theme, colorMode] = useMode();
  const [authenticated, setAuthenticated] = useState(false);




  return (
    <div> {/* Enclosing in a div */}
      {authenticated ? (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MyProSidebarProvider>
              <div style={{ height: "100%", width: "100%" }}>
                <main>
                  <Topbar setAuthenticated={setAuthenticated} />
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/administrators" element={<Team />} />
                    <Route path="/contacts" element={<Contacts />} />
                    <Route path="/teachers" element={<Invoices />} />
                    <Route path="/add/student" element={<StudentForm />} />
                    <Route path="/add/examination" element={<ExaminationForm />} />
                    <Route path="/bar" element={<Bar />} />
                    <Route path="/pie" element={<Pie />} />
                    <Route path="/line" element={<Line />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/geography" element={<Geography />} />
                  </Routes>
                </main>
              </div>
            </MyProSidebarProvider>
          </ThemeProvider>
        </ColorModeContext.Provider>
      ) : (
        <LoginPage setAuthenticated={setAuthenticated} />
      )}
    </div>
  );
};

export default App;
