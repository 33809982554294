import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useMediaQuery } from '@mui/material';
import Header from '../../components/Header';

const ExaminationForm = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  
  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const initialValues = {
    examinationDate: '',
    examinationSubject: '',
    examinationVenue: '',
  };

  const examinationSchema = yup.object().shape({
    examinationDate: yup.string().required('Required'),
    examinationSubject: yup.string().required('Required'),
    examinationVenue: yup.string().required('Required'),
  });

  return (
    <Box m="20px">
      <Header title="EXAMINATION FORM" subtitle="Enter Examination Details" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={examinationSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
            >
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Examination Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.examinationDate}
                name="examinationDate"
                error={!!touched.examinationDate && !!errors.examinationDate}
                helperText={touched.examinationDate && errors.examinationDate}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Examination Subject"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.examinationSubject}
                name="examinationSubject"
                error={!!touched.examinationSubject && !!errors.examinationSubject}
                helperText={touched.examinationSubject && errors.examinationSubject}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Examination Venue"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.examinationVenue}
                name="examinationVenue"
                error={!!touched.examinationVenue && !!errors.examinationVenue}
                helperText={touched.examinationVenue && errors.examinationVenue}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ExaminationForm;
